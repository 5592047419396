import React from "react";
import Layout from "../components/layout";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import StandardHeader from "../assets/elements/StandardHeader";
import ThreeColumnUseCaseSection from "../assets/elements/ThreeColumnUseCaseSection";
import ThreeColumnFeatureSection from "../assets/elements/ThreeColumnFeatureSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import Cta from "../partials/Cta";
import {
  CubeIcon,
  SparklesIcon,
  AnnotationIcon,
  AcademicCapIcon,
  ShieldCheckIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";

import CaseStudyMSK from "../partials/CaseStudyMSK";
import CaseStudyKings from "../partials/CaseStudyKings";
import CaseStudyStanford from "../partials/CaseStudyStanford";
import { StaticImage } from "gatsby-plugin-image";

import imgUseCasePolypWebm from "../assets/images/uses-polyp.webm";
import SEO from "../components/seo";
import VideoComponent from "../components/VideoComponent";

const useCasesImageHeight = 198;
const useCasesImageWidth = 352;

const useCases = [
  {
    name: "Gastroenterology",
    description: "AI for precision gastroenterology.",
    bullets: [
      "Track polyps in video",
      "Colonography and scope videos in one tool",
      "Support Mayo and UCEIS scoring rubrics",
    ],
    image: (
      <VideoComponent
        loop
        autoPlay
        muted
        playsInline
        width={useCasesImageWidth}
        height={useCasesImageHeight}
      >
        <source src={imgUseCasePolypWebm} type="video/webm" />
      </VideoComponent>
    ),
    learnMore: "/gastroenterology/",
  },
  {
    name: "Radiology",
    description: "Annotate modalities such as CT, MRI, and X-ray.",
    bullets: [
      "High-precision annotation of voxels",
      "Hanging protocols",
      "Axial, coronal, and sagittal view",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/uses-x-ray.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Radiology"}
      />
    ),
    learnMore: "/radiology/",
  },
  {
    name: "Microscopy",
    description: "Handle high-resolution microscopy images with ease.",
    bullets: [
      "Image processing and filtering options",
      "Automated measuring capabilities",
      "Tools for fine-grained segmentations",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/uses-microscopy.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Microscopy"}
      />
    ),
  },
  {
    name: "Ultrasound",
    description: "Annotate ultrasound data with high accuracy.",
    bullets: [
      "Expert review workflows",
      "Object detection, classifications and segmentations",
      "Handle 2D and 3D",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/uses-ultrasound.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Ultrasound"}
      />
    ),
    learnMore: "/ultrasound/",
  },
  {
    name: "Dermatology",
    description: "Label and classify a variety of conditions.",
    bullets: [
      "Flexible label taxonomies",
      "Pixel perfect segmentation of lesions",
      "Custom review processes keep experts in the loop",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/Dermatology.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Dermatology"}
      />
    ),
  },
  {
    name: "Surgical devices",
    description: "Accurately track devices in surgical videos.",
    bullets: [
      "Native video capabilities for streamlined annotation",
      "Automation features reduce manual burdens",
      "100% data-secure for privacy guarantees",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/Surgical devices.webp"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Surgical devices"}
      />
    ),
  },
];

const featuresGrid = [
  {
    name: "Specialised tools",
    description:
      "Annotate medical imaging data such as microscopy images, ultrasound videos, and volumetric DICOM images, with one platform.",
    icon: CubeIcon,
  },
  {
    name: "Expert review",
    description:
      "Define custom expert review workflows to make the most of your domain experts.",
    icon: AcademicCapIcon,
  },
  {
    name: "Automated quality assessment",
    description:
      "Combine human specialists with automated intelligence to ensure the highest quality standard for your data and labels.",
    icon: SparklesIcon,
  },
  {
    name: "Automated medical video and imaging labeling",
    description:
      "Use our object tracking & interpolation features to reduce costs. Use micro-models to accelerate your active learning workflows and get to production faster.",
    icon: LightningBoltIcon,
  },
  {
    name: "Compliant & secure",
    description:
      "Capture maximal efficiency gains of our automation features while retaining 100% control of your data.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Auditable",
    description:
      "Ensure better, faster, and cheaper approval processes. Closely monitor annotator throughput and quality.",
    icon: AnnotationIcon,
  },
];

export default function Healthcare({ location }) {
  return (
    <Layout location={location}>
      {/*  Page illustration */}
      <LeftColours />
      <RightColours />

      <StandardHeader
        title={
          "Purpose-built software tools to accelerate computer vision in healthcare"
        }
        description={
          "Encord empowers leading medical institutions and companies to address some of the hardest challenges in computer vision for healthcare."
        }
      />

      {/* Use cases */}
      <ThreeColumnUseCaseSection
        caseStudy={null}
        title={"Use cases"}
        header={"Transforming medical imaging for better patient outcomes"}
        description={
          <>
            Computer vision is revolutionising progress in medical imaging -
            from diagnostics and pathology to robotic surgery.
          </>
        }
        useCases={useCases}
      />

      <CaseStudyStanford />

      <br />

      {/* Three column feature section */}
      <ThreeColumnFeatureSection
        title={"How it works"}
        header={"Emphasizing medical data quality for healthcare"}
        description={
          "Expert review workflows, fully auditable, automated data quality checks. Fully compliant medical-specific tooling for medical imaging annotation."
        }
        features={featuresGrid}
      />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={false}
        title={"Invest"}
        header={"Our investors 2"}
        description={"Our investors"}
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../assets/images/platform-dicom-cropped.png"}
            width={900}
            alt="DICOM Cropped"
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../assets/images/platform-dicom-3d.png"}
            width={900}
            alt="3D DICOM"
          />
        }
        headerOne={"Native medical imaging capabilities"}
        headerTwo={"Granular quality control"}
        descriptionOne={
          "We support all common medical image modalities including ultrasound, gastro videos, and offer a leading solution for volumetric DICOM images."
        }
        descriptionTwo={
          "From satisfying regulatory and compliance requirements to offering customisable annotation review processes, our software is developed alongside, and designed for use by medical experts."
        }
        learnMoreOne={"/dicom/"}
      />

      <br />

      <CaseStudyKings externalLink={`https://www.researchgate.net/publication/350884644_AI_everywhere_in_endoscopy_not_only_for_detection_and_characterization`} />

      <CaseStudyMSK />

      <br />

      <Cta
        location={location}
        pageName={"Healthcare Page"}
        ctaText={"AI-accelerated medical image segmentation"}
      />
    </Layout>
  );
}

export const Head = () => (
  <SEO
    title="AI-Powered Medical Image Annotation and Segmentation | Encord"
    description="We're helping leading medical institutions and companies solve their hardest problems with state-of-the art annotation & model validation tooling."
  >
    <meta name="robots" content="noindex, nofollow" />
  </SEO>
);
